@use './_global-style.scss' as global;

body {
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}  

h1, h2, h3, h4, h5, h6 {
    color: global.$heading-color;
}

.mainContent {
    background-color: global.$app-background;
}

