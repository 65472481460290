$unit: 1rem;
$white: white;
$heading-color: #2e2e2e;
$app-background: #d9dbe6;

// Primary Accent Color
$primary-accent: #3d3c3c;

// Secondary Accent Colors
$secondary-accent-1: #b5c0ff;
$secondary-accent-2: #66754d;

// Neutral Colors
$text-color: #2e2e2e;
$off-white: #f4f4f7;
$soft-gray: #a8aab7;

// Highlight Colors
$highlight-1: #ff7878;
$highlight-2: #ffd36e;

$twitter-blue: #1da1f2;


