@use '../../_global-style.scss' as global;

$max-width: 1200px;
$border-radius: 30px;
$feature-alt-bg: #F0F7FF; // Light blue background for alternating sections

.landingContainer {
    background-color: global.$app-background;
}

.landingIntroContainer{
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 40px;
}

// Add this new class for full-width sections
.fullWidthSection {
  width: 100%;
  max-width: none;
  padding: 0;
}

.landingHeader {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landingLogo {
  font-size: 24px;
  font-weight: bold;
  color: global.$primary-accent;
}

.landingButtonContainer {
  display: flex;
  gap: 10px;
}

.landingButton {
  padding: 10px 20px;
  border-radius: $border-radius;
  font-size: 14px;
  cursor: pointer;
  border: none;
}

.landingLoginButton {
  background-color: global.$soft-gray;
  font-weight: bold;
}

.landingSignupButton {
  background-color: global.$primary-accent;
  color: global.$off-white;
  font-weight: bold;
}

.landingHeadline {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: global.$unit * 3;
  text-align: center;
}

.landingSubheadline {
  font-size: 18px;
  margin-bottom: global.$unit * 2;
  text-align: center;
}

.landingFeatureBoxes {
  display: flex;
  gap: 20px;
  position: relative;
  text-align: center;
  padding: 60px
}

.landingFeatureBoxes::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at center,
    rgba(185, 191, 194, 0.3) 0%,
    rgba(185, 191, 194, 0) 70%
  );
  pointer-events: none;
  z-index: 0;
}

.landingFeatureBox {
  width: calc(33.33% - 10px);
  background-color: global.$off-white;
  border-radius: $border-radius;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 60px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.landingFeatureBox:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
}

.landingFeatureBoxMiddle {
  width: calc(38% - 20px);
  height: 400px;
  z-index: 2;
  transform: translateY(-30px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  margin: 0 -2%;
}

.landingFeatureBoxMiddle:hover {
  transform: translateY(-35px);
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.25);
}

.landingFeatureIcon {
  font-size: 80px;
  color: global.$primary-accent;
}

.landingFeatureTitle {
  font-size: 24px;
  font-weight: bold;
  margin: 15px 0;
}

.landingFeatureDescription {
  font-size: 14px;
  line-height: 1.5;
}

.landingFeatureSignupButton {
  background-color: global.$primary-accent;
  color: global.$off-white;
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 14px;
  margin: 20px auto 0;
  display: block;
}

.landingFeatureBoxMiddle .landingFeatureSignupButton {
  width: 100%;
  padding: 16px 24px;
  font-size: 16px;
  margin-top: 30px;
}

.landingTwitterIcon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 24px;
  color: global.$twitter-blue;
}

.featureSection {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 4rem 0;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease-out forwards;

  &:nth-child(even) {
    flex-direction: row-reverse;
    background-color: $feature-alt-bg;
  }
}

.featureSectionInner {
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  align-items: center;
}

.featureImage {
  flex: 0 0 40%;
  padding: 2rem;

  img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.05) rotate(1deg);
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    }
  }
}

.featureContent {
  flex: 1;
  padding: 2rem 3rem;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 50px;
      height: 3px;
      background-color: global.$secondary-accent-1;
    }
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.5rem;
  }

  .featureButton {
    display: inline-block;
    padding: 12px 24px;
    background-color: global.$primary-accent;
    color: global.$off-white;
    border-radius: 30px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
      background-color: darken(global.$primary-accent, 10%);
      transform: translateY(-2px);
    }
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Media query for responsiveness
@media (max-width: 768px) {
  .featureSectionInner {
    flex-direction: column !important;
    padding: 3rem 1rem;
  }

  .featureImage {
    flex: 0 0 100%;
    margin-bottom: 2rem;
  }

  .featureContent {
    padding: 0 1rem;
  }
}

// Media Queries
@media (max-width: 1024px) {
    .landingFeatureBox {
      width: calc(50% - 20px);
    }
  
    .landingFeatureBoxMiddle {
      width: calc(50% - 20px);
      min-height: auto;
      transform: none;
    }
  
    .landingFeatureBoxMiddle:hover {
      transform: translateY(-5px);
    }
  }
  
  @media (max-width: 768px) {
    .landingFeatureBoxes {
        display: inline;
    }
    .landingFeatureBox {
      width: 100%;
      min-height: auto;
      margin-bottom: 20px;
      padding: 0;
    }
  
    .landingFeatureBoxMiddle {
      width: 100%;
      transform: none;
      margin: 0 0 20px 0;
    }
  
    .featureSectionInner {
      flex-direction: column !important;
      padding: 3rem 1rem;
    }
  
    .featureImage {
      flex: 0 0 100%;
      margin-bottom: 2rem;
    }
  
    .featureContent {
      padding: 0 1rem;
    }
  
    .landingIntroContainer {
      padding: 0 20px;
    }
  
    .landingHeader {
      flex-direction: column;
      height: auto;
      padding: 20px 0;
    }
  
    .landingButtonContainer {
      margin-top: 10px;
    }
  
    .landingHeadline {
      font-size: 36px;
    }
  
    .landingSubheadline {
      font-size: 16px;
    }
  
    .landingFeatureBoxes {
      padding: 30px 20px;
    }
    .landingFeatureSignupButton {
      width: auto;
    }
  }
